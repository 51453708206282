import React from 'react';
import {
  Layout,
  Actions,
  Button,
  LayoutBlock,
  Container,
  Column,
  Row,
  Section,
  Notice,
  Block,
  Hero,
  Title,
  Description,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO } from '../../../components/_index';

const StrategicBountyProjects = () => {
  return (
    <PageLayout>
      <SEO
        title="Dragonchain Strategic Projects Bounty Program"
        description="The Strategic Projects Bounty Program challenges users and teams to build predefined projects as imagined and desired by members of the community and endorsed by the Dragonchain team, using Dragonchain as a blockchain platform."
      />
      <Hero type="code" backgroundImage="https://images.dragonchain.com/texture/clouds-blue@2x.jpg">
        <Title color="white">Dragonchain Strategic Projects Bounty Program</Title>
        <Description color="blue-light">
          <p>
            The Strategic Projects Bounty program challenges developers and teams to build predefined
            projects, set by Dragonchain’s leadership. Each strategic project has its own set of guidelines,
            expectations, rewards, and time frame as outlined in the project specific documentation.
          </p>
          <Actions
            actions={[
              {
                name: 'Rules',
                color: 'blue',
                hover: 'blue-dark',
                blank: true,
                to:
                  'https://docs.google.com/document/d/1evvjSIxI2TLPw-QILH6kh_PHZD-xD0bIgY_41GimLp0/edit?usp=sharing',
              },
              {
                name: 'Express interest',
                color: 'blue-dark',
                hover: 'blue-dark',
                blank: true,
                to:
                  'https://docs.google.com/forms/d/e/1FAIpQLSeB9HdjKZwiSLrA86gGmc2HOqY6y0XiSe8-KRX0nr3tpQx3Kg/viewform',
              },
            ]}
          />
        </Description>
      </Hero>
      <LayoutBlock>
        <Container>
          <Row auto span={6} gap="medium">
            <Column>
              <Section
                title="Source Code Control"
                subtitle="Value in Dragons - $5,000"
                footer={
                  <Button type="link" hover="blue-dark" to="./git">
                    Learn More
                  </Button>
                }
              >
                Project for creation of a Dragonchain-backed Github replacement system for decentralized
                software source code control. This project should integrate open source Git and Gitlab/Github
                into Dragonchain with optional integration of Github via webhooks. This project is open for
                development.
              </Section>
            </Column>
            <Column>
              <Section
                title="Stored Procedure Contracts"
                subtitle="Up-to Dragon value - $2,500"
                footer={
                  <Button
                    blank
                    hover="blue-dark"
                    type="link"
                    to="https://docs.google.com/document/d/1G9efveR5OMips3fomhhydTCulVOPDvneA7Fa8_qWkTo"
                  >
                    Learn More
                  </Button>
                }
              >
                Create Dragonchain watcher/publisher smart contracts such that an occurrence on a Dragonchain
                L1 triggers a change on a PostgreSQL database, SQL Server, MongoDB, Cassandra, Hadoop or
                others. Integration of stored procedures into Dragonchain as smart contracts.
              </Section>
            </Column>
          </Row>
          <Row auto span={6} gap="medium">
            <Column>
              <Section
                title="Decentralized Centralized Logging"
                subtitle="Value in Dragons - $5,000"
                footer={
                  <Button
                    blank
                    hover="blue-dark"
                    type="link"
                    to="https://docs.google.com/document/d/1BK2Rnzsfflb00aY8n86YLRcc5uBqBCnjQFyNAFTEhGo/edit?usp=sharing"
                  >
                    Learn More
                  </Button>
                }
              >
                Create an extension or integration into the Linux/UNIX centralized logging system syslog-ng.
                Will allow configuration of criteria for logs to be forwarded to a Dragonchain business node
                (L1) for capture/ledgering of decentralized proof through Dragon Net into Level 5 (public)
                networks. Will also allow customization at the business node for other activity based upon
                smart contracts. Will bypass syslog-ng server requirement, allowing L1 node to perform same
                functions in decentralized manner.
              </Section>
            </Column>
            <Column>
              <Section
                title="Hardware Integrations"
                subtitle="Up-to Dragon value - $3,500"
                footer={
                  <Button
                    blank
                    hover="blue-dark"
                    type="link"
                    to="https://docs.google.com/document/d/198oVWlEtve9F-qQuVrpMxyN2DrdnSALDmhU2rcP2DDo/edit?usp=sharing"
                  >
                    Learn More
                  </Button>
                }
              >
                Run nodes on alternative architectures such as ARM for mobile and Raspberry Pi. Integrate
                sensors with an L1 node. This project is open for development. No due date, first to complete
                each component earns respective reward.
              </Section>
            </Column>
          </Row>
          <Row auto span={6} gap="medium">
            <Column>
              <Section title="Workflow" subtitle="Up to $5,000">
                Redmine, Bugzilla, Trac, Jira, etc. Streamline operations and reduce redundancy with smart
                contract automation.
              </Section>
            </Column>
            <Column>
              <Section title="Gaming" subtitle="Up to $500 or $1,000 depending on integration.">
                Create a game that integrates Dragonchain into its operation or game mechanics. Examples:
                Integration into open source game, Creation of an in game marketplace for items, Leaderboard
                with proof, Scarcity of items, Tracking of items in game, General incentives for gameplay
                actions
              </Section>
            </Column>
          </Row>
          <Layout>
            <Block>
              <Notice>
                <p>If you have questions about any of our bounties, go to our Telegram channel</p>
                <Button blank type="link" color="blue" hover="blue-dark" to="https://t.me/dragontalk">
                  Telegram
                </Button>
              </Notice>
            </Block>
          </Layout>
          <Row auto span={6} gap="medium">
            <Column>
              <Section title="Analytics" subtitle="Up to $2,500">
                Integration of Dragonchain into various analytics platforms (plugging in google analytics or a
                similar platform). Provide proof of reported analytics.
              </Section>
            </Column>
            <Column>
              <Section title="AI/ML/NL" subtitle="Up to $5,000">
                AI/ML/NL
              </Section>
            </Column>
          </Row>
          <Row auto span={6} gap="medium">
            <Column>
              <Section title="Loyalty" subtitle="Up to $2,500">
                Optionally integrate your loyalty &amp; rewards systems with your products, let consumers tip
                farmers, or invent something new with us.
              </Section>
            </Column>
            <Column>
              <Section title="FreeNAS" subtitle="Up to $2,500">
                Creation of a FreeNAS integration, focusing on backup services plugin components. Allow users
                to create or purchase a FreeNAS system with Dragonchain-backed proof of storage and chain of
                custody capabilities. Would allow simple ledgering as well as smart contract based actions.
              </Section>
            </Column>
          </Row>
          <Row auto span={6} gap="small">
            <Column>
              <Section title="Incentivization of Project Activity" subtitle="Up to $2,500">
                Creation of a system for the incentivization of developers and community in open source or
                internal systems. Includes ability to integrate a direct mining component based upon
                application specific performance indicators
              </Section>
            </Column>
            <Column>
              <Section title="Enterprise Integration" subtitle="Up to $10,000">
                Integrate any of the many open APIs available for SAP integration. Integration of Smartsheet
                API to track/prove/incentivize Smartsheet actions. Primary initial focus is on webhooks and
                events.
              </Section>
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default StrategicBountyProjects;
